
import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend({
  computed: {
    ...mapState('common', {
      isBusy: 'pageBusy',
    }),
  },
  beforeCreate() {
    this.$store.commit('common/isBusy', true)
  },
  mounted() {
    this.$store.commit('common/isBusy', false)
  },
})
